import { Row } from "antd"
import { Avatar } from "@amzn/awsui-chat-components";
import { SpaceBetween, TextContent } from "@amzn/awsui-components-react/polaris";

const UserBubble = (props : any) => {
    return (
        <Row
            style = {{ 
                width: "100%",
                borderRadius : "15px",
                background : "white",
                borderColor: "gray",
                borderStyle: "solid",
                borderWidth: "2px",
                padding : "12px", 
                color : "black",
            }}
        >
            {/** This component helps display the content header, content, and copy button vertically */}
            <SpaceBetween
                size = "xxs"
                direction = "vertical"
            >
                {/** Displays the content header (shows the message is from the user) */}
                <SpaceBetween
                    size = "xs"
                    direction = "horizontal"
                >
                    <Avatar
                        ariaLabel="Avatar of User"
                        tooltipText="User"
                    />
                    <TextContent>
                        <h2>
                            User    
                        </h2>
                    </TextContent>
                </SpaceBetween>
                {/** Displays the message content; the styles here outline the message content */}
                <Row
                    style = {{
                        width: "100%",
                        padding: "3px"
                    }}
                >
                    <TextContent>
                        <h5>
                            {props.message.content}     
                        </h5>
                    </TextContent>
                </Row>
            </SpaceBetween>
        </Row>
    )
}

export default UserBubble;