import React from 'react';
import { Row, Col } from "antd"
import { Avatar } from "@amzn/awsui-chat-components";
import { SpaceBetween, TextContent } from "@amzn/awsui-components-react/polaris";

// This component displays a chat bubble that tells the user that CBB Tutor is thinking of a response 
const CBBTutorLoading = () => {
    return (
        <Row 
            justify = "start" 
            style = {{
                width : "100%",
                paddingBottom : "20px",
                paddingLeft: "15px"
            }}
        >
            {/** Displays the chat bubble; the styles here outline the chat bubble  */}
            <Col 
                span = {20}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    borderRadius : "15px",
                    background : "rgb(244, 244, 244)",
                    borderColor: "purple",
                    padding : "12px",
                    borderStyle: "solid",
                }}
            >
            {/** Displays the Avatar and message horizontally */}
                <SpaceBetween
                    size = "xs"
                    direction="horizontal"
                > 
                    <Avatar
                        ariaLabel="Loading avatar of CBB Tutor assistant"
                        color="gen-ai"
                        iconName="gen-ai"
                        loading
                        tooltipText="CBB Tutor is thinking..."
                    />  
                    <TextContent>
                        <h5>
                            CBB Tutor is thinking...   
                        </h5>
                    </TextContent>
                </SpaceBetween>
            </Col>
        </Row>
    )
}

export default CBBTutorLoading;