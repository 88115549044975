import React, { useState, useEffect } from 'react';
import { Amplify, Hub } from '@aws-amplify/core';
import config from './aws-exports';
import { getCurrentUser } from 'aws-amplify/auth';
import { signInWithRedirect } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import CBBTutorProtected from './components/CBBTutorProtected';
import { SideNavigation, TopNavigation } from "@amzn/awsui-components-react/polaris";
import { Row, Col } from "antd"

// Connect the frontend with the backend 
Amplify.configure(config);

// Interface that describes an authenticated user
interface AuthUser{
  username: string;
  userId: string;
}

function App () {
  // Current user
  const [user, setUser] = useState<AuthUser | null>(null);
  // For sidebar page navigation
  const [activeHref, setActiveHref] = React.useState("cbb-tutor");

  // This is executed on the first render of the page
  useEffect(() => {
    const handleLogin = async () => {
      try {
        // Check if the user is already authenticated. If the user is already authenticated, 
        // then we will get an error message (which is ok)
        // If not authenticated, this will automatically sign the user in with Amazon Federate
        await signInWithRedirect({ provider: { custom: 'FederateOIDC' } });
      } catch (error) {
        console.log('Error signing in:', error);
        // renderApp(); // Render the app if sign-in fails
        getUser().then((userData: any) => {
          setUser(userData);
        });
      }
    };

    // Listens for any new events 
    const listener = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        // New event that user is signed in, then set the user hook
        case 'signedIn':
          getUser().then((userData: any) => {
            setUser(userData);
          });
          break;
        // New event that the user is signed out, then update the user hook accordingly
        case 'signedOut':
          setUser(null);
          break;
        // In the event that the sign in led to a failure, log the error and the event 
        case 'signInWithRedirect_failure':
          console.log('Sign in failure', payload.event);
          break;
      }
    });

    // Gets the current user that has been authenticated
    const getUser = async () => {
      try {
        const userData = await getCurrentUser();
        return userData;
      } catch {
        return console.log('Not signed in');
      }
    };

    // calls the handleLogin() function to handle the login 
    handleLogin();

    return () => {
      listener();
    };
  }, []);

  return (
    <div>
      {
        // If the user is authenticated, then display protected content 
        // The displayed protected content depends on the path the user is on 
      }
      {user != null ? (
          <div>
            {// Holds the top navigation bar
            }
            <TopNavigation
              identity={{
                href: "https://w.amazon.com/bin/view/Audible/ConsumerDomain/Vamos/Orca/genAI/",
                title: "Ignite Utils",
              }}
              utilities={[
                {
                  type: "button",
                  text: "Audible ORCA Team",
                  href:
                    "https://w.amazon.com/bin/view/Audible/ConsumerDomain/Vamos/Orca",
                  external: true,
                  externalIconAriaLabel: " (opens in a new tab)"
                }
              ]}
            />
            <Row>
              <Col
                style = {{
                  borderRightColor: "rgb(234, 237, 237)",
                  borderRightWidth: "1px",
                  borderRightStyle: "solid",
                  height: "100vh"
                }}
                span = {4}
              >
                <SideNavigation
                    activeHref={activeHref}
                    header={{
                      href:
                        "https://w.amazon.com/bin/view/Audible/ConsumerDomain/Vamos/Orca/genAI/",
                      text: "GenAI Solutions",
                    }}
                    onFollow={event => {
                      console.log(event.detail)
                      event.preventDefault();
                      if (event.detail.text !== 'GenAI Solutions') {
                        setActiveHref(event.detail.href); 
                      }
                      else{
                        window.open(event.detail.href, "_blank");
                      }
                    }}
                    items={[
                      {
                        type: "link",
                        text: "CBB Tutor",
                        href: "cbb-tutor"
                      },
                      { type: "link", text: "Coming Soon...", href: "coming-soon" }
                    ]}
                />
              </Col>
              <Col
                span = {20}
              >  
                {
                  activeHref === "cbb-tutor" ? <CBBTutorProtected/>
                    : <></>
                }
              </Col>
            </Row>
          </div>
          ) : (
              <div>Loading...</div>
          )}
    </div>
  );
}

export default App;
