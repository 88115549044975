import React from 'react';
import { Row } from "antd"
import { SpaceBetween, TextContent } from "@amzn/awsui-components-react/polaris";
import { Avatar } from "@amzn/awsui-chat-components";

const CBBTutorBubble = (props : any) => {
    // Pretty print the message content on the screen  
    const displayMessageContent = (content : string) => {
        const lines = content.split("\n");

        return (
        <div
            style = {{
            width: "100%",
            maxWidth : "100%"
            }}
        >
            {lines.map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index < lines.length - 1 && <br />}
            </React.Fragment>
            ))}
        </div>  
        );
    }
    return (
        <Row
            style = {{ 
                width: "100%",
                borderRadius : "15px",
                background : "rgb(244, 244, 244)",
                borderColor: "purple",
                color : "black",
                borderStyle: "solid",
                padding : "12px",
            }}
        >
            {/** This component helpds display the content header, content, and copy button vertically */}
            <SpaceBetween
                size = "xxs"
                direction="vertical"
            > 
                {/** This component is the content header (shows the message is from CBB Tutor) */}
                <SpaceBetween
                    size = "xs"
                    direction = "horizontal"
                >
                    <Avatar
                        ariaLabel="Avatar of CBB Tutor assistant"
                        color="gen-ai"
                        iconName="gen-ai"
                        tooltipText="CBB Tutor"
                    />
                    <TextContent>
                        <h2>
                            CBB Tutor 
                        </h2>
                    </TextContent>
                </SpaceBetween>
                {/** This component holds the message content; the styles here outline the message content */}
                <Row
                    style = {{
                        width: "100%",
                        padding: "3px"
                    }}
                >
                    <TextContent>
                        <h5>
                            {displayMessageContent(props.message.content)}     
                        </h5>
                    </TextContent>  
                </Row>  
            </SpaceBetween>
        </Row>
    )
}

export default CBBTutorBubble;