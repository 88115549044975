import React, { useEffect, useState } from 'react';
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Row, Affix } from "antd"
import { FormField, PromptInput } from "@amzn/awsui-components-react/polaris";
import { LoadingBar } from "@amzn/awsui-chat-components";
import DOMPurify from 'dompurify';
import CBBTutorMessages from './CBBTutorMessages';
import CBBTutorLoading from './CBBTutorLoading';
import CBBTutorHeader from './CBBTutorHeader';

// Message interface is applied like so: 
//    role: "user" | "assistant"
//    content: "I want to..."
interface Message {
  role: string;
  content: string;
}

const CBBTutorProtected = () => {
    // Initial message displayed by CBB Tutor to greet the user 
    const initMessage : Message = {
      role: "assistant",
      content: "Hello! My name is CBB Tutor. You can ask me any question relating to the Consolidated BuyBox. "
    }
    // Content hooks
    // All the messsages (user and Claude) displayed on the screen
    const [allMessages, setAllMessages] = useState<Message[]>([initMessage]);
    // The final user request sent to Claude after the user presses the submit button or "enter" key 
    const [userMessageFinal, setUserMessageFinal] = useState("");
    // The message the user is currently drafting in the TextArea
    const [userMessageDraft, setUserMessageDraft] = useState("");
    // A flag that indicates whether the frontend has made all the necessary preparations to send the user's request to the backend 
    const [readyToCallAPI, setReadyToCallAPI] = useState(false);
    // Stores Claude's response from the backend; is usually an array of the following format: [<Claude Response>, <Knowledge Base SessionID>]
    const [claudeResponse, setClaudeResponse] = useState<any>(null);
    // Stores response from backend
    const [backendResponse, setBackendResponse] = useState<any>(null);
    // Stores the Knowledge Base SessionID
    const [sessionId, setSessionId] = useState<string | null>(null);
    // Stores the user's model 
    // 1 represents Claude v3 Sonnet, 2 represents Claude v3 Haiku 
    // const [model, setModel] = useState(1);

    // Hooks for UI/UX
    // A flag that indicates whether or not the user is waiting on a response from the backend 
    const [loadingResponse, setLoadingResponse] = useState(false);

    // BELOW: helper functions
    // Gets the Claude responses from the backend 
    const getProtectedContent = async () => {
      try{
        // Get the current user's access and id tokens
        const { idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
        // If the idToken is valid, then continue
        if (idToken){
          const email = idToken.payload.email;
          // Use the token to make an authenticated request to your backend API
          // Pass the user request and session id in the request body 
          const restOperation = await post({
            apiName: 'apiaf345656',
            path: '/items',
            options: {
              headers: { 'Authorization': `${idToken}` },
              // body: JSON.stringify([userMessageFinal, sessionId, email, model])
              body: JSON.stringify([userMessageFinal, sessionId, email])
            }
          });

          // Retrieve Claude's response from the backend
          const { body } = await restOperation.response;
          const response = await body.json();
    
          if (response) {
            // Update the backendResponse hook with the backend's response
            setBackendResponse(response);
          } else {
            throw new Error('Failed to fetch page content');
          }
        } else{
          throw new Error('Invalid user')
        }
      } catch (error){
        // Log any errors that occur 
        console.error("Error fetching content");
        // Update the claudeResponse hook with a generic error message 
        setClaudeResponse(["Something went wrong. Check to make sure you are using valid characters in your request, then try again.", sessionId]);
      }
    }
    
    // Preparations to send the user's request to the backend 
    const handleSendText = () => {
      // Making sure the message the user wants to send isn't empty and is under the max of 1000 characters
      const userMessageLength = userMessageDraft.length;
      // Regex for input validation
      // Validates requests containing only letters, numbers, punctuation marks, and whitespace characters (including newlines and tabs), but no other special characters or control codes
      const regex = /^[\p{L}\p{N}\p{P}\p{Z}\r\n\t]+$/u;
      // If the message the user wants to send is valid 
      if (userMessageLength > 0 && userMessageLength <= 1000 && regex.test(userMessageDraft)){
        console.log("start: " + new Date().getTime());
        // Set the final user message with a sanitized version of the user's draft
        setUserMessageFinal(sanitizeInput(userMessageDraft));
        // Indicate that we are ready to call the API 
        setReadyToCallAPI(true);
      }
      else if (userMessageLength > 1000){
        setClaudeResponse(["Make sure your request is below 1000 characters.", sessionId])
      }
      else{
        setClaudeResponse(["Make sure your request includes only letters, numbers, punctuation marks, and whitespace characters.", sessionId])
      }
    } 

    const handleDisplayUserRequest= () => {
      // If the message the user wants to send is valid (has actual content), then format it and add it into the conversation history. 
      // Also, clear the userMessageDraft so nothing shows up in the TextArea when the user sends a valid message 
      if (userMessageFinal.length > 0 && readyToCallAPI){
        // Package the user's request into an interface object for easier rendering logic later on 
        const newMessage : Message = {
          role : "user",
          content : userMessageFinal
        }
        // Incorporate the newMessage into allMessages (the messages rendered on the screen)
        setAllMessages([...allMessages, newMessage]);
        // Clear the user's draft so that nothing shows up in the TextArea 
        setUserMessageDraft("");
      }
    }

    // Handles the Claude response to extract relevant information from it 
    const handleClaudeResponse = () => {
      // Claude usually outputs whitespace characters that don't render properly, so clean the response up
      const cleanedResponse = cleanClaudeResponse();
      // Format the Claude response for better rendering logic
      const claudeResponseFormatted : Message = {
        role : "assistant",
        content: cleanedResponse
      }
      // Incorporate Claude's responses into allMessages for display to the user 
      setAllMessages([...allMessages, claudeResponseFormatted]);
      // If this was the first response we got from Claude during this conversation, then set the sessionId hook with 
      // the sessionId returned from the Knowledge Base  
      if (sessionId == null){
        setSessionId(claudeResponse[1]);
      }
      console.log("end : " + new Date().getTime());
    }

    // Claude's responses are formatted with \n, /, and other characters
    // that should be cleaned before display to the user
    const cleanClaudeResponse = () => {
      const regex = /\\n|"|\\|[\u0000-\u001F\u007F-\u009F\u2000-\u206F\u2E00-\u2E7F\u3000-\u303F\uD800-\uDFFF]|\\/gu;
      const replacements: { [key: string]: string } = {
        '\\n': '\n',
        '"': '',
        '\\': '"',
        '\\"': '',
        // Add any additional replacements for specific characters here
      };
      const fixContent = JSON.stringify(claudeResponse[0]);
      const cleanedContent = fixContent.replace(regex, (match) => {
        // Type guard to ensure that `match` is a key in `replacements`
        if (match in replacements) {
          return replacements[match as keyof typeof replacements];
        } else {
          return '';
        }
      });    
      return cleanedContent;
    };

    // Sanitize user input using DOMPurify 
    function sanitizeInput(input : string) {
      const sanitizedInput = DOMPurify.sanitize(input);
      return sanitizedInput;
    }

    // BELOW: useEffect hooks related to getting information to and from the backend 
    useEffect(() => { 
      // If the user wants to send a message, display it on the screen 
      handleDisplayUserRequest();
    }, [userMessageFinal])

    useEffect(() => {
      // If the conversation history changes due to a user request (in other words, the user has sent a request),
      // then make a call to the backend and set the loading status to true 
      if (allMessages.length > 0 && readyToCallAPI){
        // We aren't ready to call the backend API because we are processing a request right now 
        setReadyToCallAPI(false);
        // Process the user request 
        getProtectedContent();
        // Set this flag to true because we are now waiting for a response from Claude
        setLoadingResponse(true);
      }
    }, [allMessages]) 

    useEffect(() => {
      if (backendResponse !== null && backendResponse !== undefined && 
        backendResponse.body !== null && backendResponse.body !== undefined){
        const body = backendResponse.body;
        setClaudeResponse(JSON.parse(body))
      }
    }, [backendResponse])

    useEffect(() => {
      // If the backend returns a valid Claude response that changes the 
      // claudeResponse hook, then incorporate it properly into our 
      // conversation history (allMessages hook) for rendering
      if (claudeResponse !== null && claudeResponse !== undefined && claudeResponse[0] !== undefined 
        && claudeResponse[0] !== null && claudeResponse[0].length > 0 ){
        // Incorporate Claude's response properly and extract relevant information from it 
        handleClaudeResponse();
        // Set this flag to false because we are no longer waiting on a response 
        setLoadingResponse(false);
        // Clear the user's final message because it has already been processed
        setUserMessageFinal("");
      }
    }, [claudeResponse])

    return (
      <div>
        {/** Holds the header */}
        {/* <CBBTutorHeader model = {model} setModel = {setModel}/> */}
        <CBBTutorHeader/>

        {/** This component holds all the chat bubbles */ } 
        <Row
          style={{ 
            paddingBottom : "144px",
            paddingTop : "10px",
            paddingLeft : "10px",
            paddingRight : "10px"
          }}
        >
          {
            // Display all the messages in the form of chat bubbles
            allMessages.map((message, index) => {
                return <CBBTutorMessages index = {index} message = {message}/>
            })
          }
          {
            // If we're loading a response from Claude, display a message to let the user know
            loadingResponse ? (
                <CBBTutorLoading/>
            )
            : 
            (<></>)
          }
        </Row>
        
        {/** Code for the input textbox and the send button */}
        <Affix 
          style={{ 
            position : 'fixed', 
            bottom : 0, 
            width : '83%',
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "20px",
            paddingBottom: "20px",
            backgroundColor: "white"
          }}>
            <FormField
              stretch={true}
              label="User prompt"
              constraintText={
                <>Character count: {userMessageDraft.length} / 1000</>
              }
              errorText={
                userMessageDraft.length > 1000 &&
                "The prompt has too many characters."
              }
            >
              { // If loading a response from Claude, we don't want users to be able to send another message
                  loadingResponse ? 
                    (
                      <LoadingBar variant="gen-ai-masked" />                        
                    ) : (
                        (<PromptInput
                          onChange={({ detail }) => setUserMessageDraft(detail.value)}
                          onAction = { handleSendText }
                          value={userMessageDraft}
                          actionButtonAriaLabel="Send message"
                          actionButtonIconName="send"
                          placeholder="Ask a question"
                          spellcheck
                          disableActionButton = {userMessageDraft.length === 0}
                        />)
                    )
                }
            </FormField>
        </Affix>
      </div>
    )
}

export default CBBTutorProtected;