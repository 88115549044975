/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "apiaf345656",
            "endpoint": "https://c6vfjhndt6.execute-api.us-west-2.amazonaws.com/mainline",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:814fe408-aa22-4e63-8dc2-14cdd230e07e",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ClrQBSRed",
    "aws_user_pools_web_client_id": "5gj6knuh22khcgkqlhqna9gt38",
    "oauth": {
        "domain": "igniteutilsb610236e-b610236e-mainline.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://orca.audible-vamos.amazon.dev/,http://localhost:3000/",
        "redirectSignOut": "https://orca.audible-vamos.amazon.dev/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
