import { Row } from "antd"
import { Header, Link } from "@amzn/awsui-components-react/polaris";
// import { Select, SpaceBetween, TextContent } from "@amzn/awsui-components-react/polaris";
// import React, { useEffect, useState } from 'react';

const CBBTutorHeader = (props : any) => {
  // The commented out parts are here for eventual scaling if we want to include the option of choosing which model the user wants to use

  // const [selectedOption, setSelectedOption] = useState({
  //   label: "Claude v3 Sonnet",
  //   value: "1",
  //   description:
  //     "This model will return slower, yet most complex responses. If you are new to the Consolidated BuyBox documentation, we recommend you use this model for the best possible experience."
  // });

  // useEffect(() => {
  //   props.setModel(selectedOption.value);
  // }, [selectedOption])

  return (
    <Row
      style = {{
        paddingLeft: "27px",
        paddingTop: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        backgroundColor: "white",
        marginBottom: "5px"
      }}
    >
      {/** Holds the overall header component of the website */}
      <Header
        variant = "h1"
        info={
          <Link
            href='https://w.amazon.com/bin/view/Users/yualudi/Quip/IgniteUtilsOnboardingDocument/'
            variant="info"
          >
            Info
          </Link>
        }
        // actions = {
        //   <SpaceBetween
        //     size = "xs"
        //     direction = "horizontal"
        //   >
        //     <TextContent>
        //       <h4>
        //         Chosen Model
        //       </h4>
        //     </TextContent>
        //     {/* Users can choose which model they want to use to answer their question */}
        //     <Select
        //       selectedOption={selectedOption}
        //       onChange={({ detail }) => 
        //         setSelectedOption(detail.selectedOption as { label: string; value: string; description: string; })
        //       }
        //       options={[
        //         {
        //           label: "Claude v3 Sonnet",
        //           value: "1",
        //           description:
        //             "This model will return slower, yet more complex responses. If you are new to the Consolidated BuyBox documentation, we recommend you use this model for the best possible experience."
        //         },
        //         {
        //           label: "Claude v3 Haiku",
        //           value: "2",
        //           description:
        //             "This model will return faster, yet less complex responses. If you are part of the ORCA team and are familiar with the Consolidated BuyBox documentation, we recommend you use this model."
        //         }
        //       ]}
        //       filteringType="auto"
        //     />
        //   </SpaceBetween>
          
        // }
      >
        CBB Tutor
      </Header>
    </Row>
  )
}

export default CBBTutorHeader;