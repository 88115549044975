import { CopyToClipboard } from "@amzn/awsui-components-react/polaris";
import { Row, Col } from "antd"
import CBBTutorBubble from './CBBTutorBubble';
import UserBubble from "./UserBubble";

const CBBTutorMessages = (props : any) => {
  return (
      (props.message.role === "user") ? 
          <Row 
            key = {props.index}
            justify = "end" 
            style = {{
              width : "100%",
              paddingBottom : "20px",
              paddingRight: "25px"
            }}
          >
            {/** Display's user's request */}
            <Col 
              span = {16} 
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                wordBreak: "break-word" // Break long words to prevent overflow
              }}
            >
              <div style = {{ width : "100%" }}>
                {/** Displays the user's chat bubble */}
                <UserBubble message = {props.message}/>
                {/** Displays the copy button */}
                <Row
                    style = {{
                      justifyContent: "end", 
                      width: "100%"
                    }}
                  >
                    <CopyToClipboard
                      copyButtonAriaLabel="Copy user question"
                      copyErrorText="Question failed to copy"
                      copySuccessText="Question copied"
                      textToCopy={props.message.content}
                      variant="icon"
                    />
                  </Row>
              </div>
            </Col>
          </Row>
        : 
          <Row 
            key = {props.index}
            justify = "start" 
            style = {{
              width : "100%",
              paddingBottom : "20px",
              paddingLeft: "15px"
            }}
          >
            {/** Display's CBB Tutor's response */}
            <Col 
              span = {16}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                wordBreak: "break-word", // Break long words to prevent overflow
              }}
            >
              <div style = {{ width: "100%" }}>
                {/** This component displays CBB Tutor's chat bubble */}
                <CBBTutorBubble message = {props.message}/>
                {/** This component holds the copy button */}   
                <Row
                  style = {{
                    justifyContent: "end", 
                  }}
                >
                  <CopyToClipboard
                    copyButtonAriaLabel="Copy response"
                    copyErrorText="Response failed to copy"
                    copySuccessText="Response copied"
                    textToCopy={props.message.content}
                    variant="icon"
                  />
                </Row>    
              </div> 
            </Col>
          </Row>
  )
}

export default CBBTutorMessages;